<template>
  <b-card class="program-update-tab-card">
    <b-card-title>
      Reports
    </b-card-title>
    <b-card-sub-title>
      Control report visibility for Champions
    </b-card-sub-title>
    <b-form class="mt-2">
      <b-row>
        <b-col 
          v-for="section in reportSections"
          :key="section.id"
          sm="12"
        >
          <div
            class="d-flex justify-content-start mt-4"
          >
         
            <b-form-group
              :label="section.reportTitle"
              :label-for="'section-switch-' + section.id"
              class="mb-0 mr-50 " 
            >
              <action-checkbox
                :checked="localProgram[section.id]"
                :name="'section' + section.id"
                switch
                inline
                @clickNativePrevent="toggleSectionVisibility(section)"
              />
            </b-form-group>
            <feather-icon
              v-b-tooltip.hover="section.tooTip"
              icon="AlertCircleIcon"
              size="16"
            />
          </div>
          <div
          >
          <vue-good-table
            class="mt-3"
            mode="remote"
            :is-loading="isLoading"
            :columns="columns"
            :rows="segmentData[section.id] || []"
            :pagination-options="{
              enabled: false
            }"
            style-class="vgt-table striped"
          >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'name'"
              cols="12"
              md="2"
              class="d-flex vgt-center-align"
            >
                {{ props.row.name }}
            </div>
            <div
              v-if="props.column.field === 'description'"
              cols="12"
              md="2"
              class="d-flex vgt-center-align"
            >
                {{ props.row.description }}
            </div>
            <div
              v-if="props.column.field === 'display_to_champion'"
              cols="12"
              md="2"
              class="d-flex vgt-center-align">
              <action-checkbox
                :checked="props.row.display_to_champion"
                :name="'segment' + props.row.id"
                switch
                inline
                @clickNativePrevent="() => toggleSegmentVisibility(props.row)"
              />
            </div>
            <!-- Column: Actions -->
          </template>
          </vue-good-table>  
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import programsService from "@/services/programsService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { reportSections } from "@/models";
import ActionCheckbox from "@/views/components/common/ActionCheckbox.vue";
import { exec } from "apexcharts";
import {VueGoodTable} from "vue-good-table";
import { mapGetters } from 'vuex';


export default {
  components: {
    VueGoodTable,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    ActionCheckbox
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
  },
  data() {
    return {
      segmentData: {},
      localProgram: { ...this.program },
      isLoading: false,
      columns: [
        {
          label: "Segment",
          field: "name",
          sortable: false,
        },
        {
          label: "Visibility",
          field: "display_to_champion",
          sortable: false,

        },
        {
          label: "Description",
          field: "description",
          sortable: false,

        },
      ],
    };

  },
  async created() {
    try {
      this.isLoading = true;
      const result = await programsService.getProgramReportConfig(this.program.id);
      this.isLoading = false;

      const segments = result.data.segments;
      segments.map((segment) => {
        segment.section = segment.report_segment.section;
        segment.name = segment.report_segment.name;
        segment.description = segment.report_segment.description;
      })

      this.segmentData = segments.reduce((acc, segment) => {
        const key = segment.section; // Use section name as key
        if (!acc[key]) acc[key] = [];
        acc[key].push(segment);
        return acc;
      }, {});
    } catch (e) {
      console.log(e);
      this.$toast(makeErrorToast()); 
    }
  },
  methods: {
    async toggleSectionVisibility(section) {
       try {

        this.localProgram[section.id] = !this.localProgram[section.id];
        const data = { section_id: section.id, is_enabled: this.localProgram[section.id] };

        const response = await programsService.updateProgramReportSections(this.program.id, data);
        if (response && response.data) {
          this.$toast(makeSuccessToast("Section updated successfully."));
        }
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Something went wrong. Report sections not saved"));
        this.$log.error(e);
      }
    },
  async toggleSegmentVisibility(segment) {
        try {
          segment.display_to_champion = !segment.display_to_champion;
          const data = { segment_id: segment.id, display_to_champion: segment.display_to_champion };

          const response = await programsService.updateProgramReportSegments(this.program.id, data);
          if (response && response.data) {
            this.reloadTables()
            this.$toast(makeSuccessToast("Segment updated successfully."));
          }
        } catch (e) {
          console.log(e);
          this.$toast(makeErrorToast("Something went wrong. Report segment not saved"));
          this.$log.error(e);
        }
  },
  async reloadTables() {
    try {
      const result = await programsService.getProgramReportConfig(this.program.id);
      const segments = result.data.segments;
      segments.map((segment) => {
        segment.section = segment.report_segment.section;
        segment.name = segment.report_segment.name;
        segment.description = segment.report_segment.description;
      })

      this.segmentData = segments.reduce((acc, segment) => {
        const key = segment.section; // Use section name as key
        if (!acc[key]) acc[key] = [];
        acc[key].push(segment);
        return acc;
      }, {});

    } catch (e) {
      console.log(e);
      this.$toast(makeErrorToast()); 
    }
  }
},
  setup() {
    return {
      reportSections
    };
  }
};
</script>

<style lang="scss">
  .vgt-responsive {
    min-height: unset;
  }
</style>

